<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <div :class="'toptable' + topTableClassId + ' card card-custom card-stretch gutter-b'">
            <slot name="header">
                <div :class="'card-header border-0 py-5' + (custom_class ? ' ' + custom_class : '')">
                    <slot name="title">
                        <h3 class="card-title align-items-start flex-column">
                            <span class="card-label font-weight-bolder text-dark">{{ labels.plural }}</span>
                            <span class="text-muted mt-3 font-weight-bold font-size-sm">
                                <slot name="description">{{ labels.description }}</slot>
                            </span>
                        </h3>
                    </slot>
                    <slot name="title-actions" v-bind:values="{ top_actions: top_actions, doAction: doAction }">
                        <div v-if="top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in top_actions" href="#"
                                :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder' + ((index < (top_actions.length - 1)) ? ' mr-3' : '')"
                                @click.prevent="doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{ action.label }}
                            </a>
                        </div>
                    </slot>
                </div>
            </slot>
            <slot name="body" v-bind:values="{ fields: fields, items: items }">
                <div :class="'card-body pt-0 pb-3' + (custom_class ? ' ' + custom_class : '')">
                    <slot name="before-filters"></slot>
                    <slot name="tab-contend">
                        <div class="tab-content">
                            <slot name=filters>
                                <div v-if="hasFilters"
                                    :class="{ 'border': openFilters, 'border-primary': openFilters && !isFiltered, 'border-info': openFilters && isFiltered }"
                                    class="card card-custom">
                                    <div :class="{ 'bg-primary': !isFiltered, 'bg-info': isFiltered }"
                                        class="card-header">
                                        <div class="card-title">
                                            <h3 class="card-label text-white">Ricerca Avanzata</h3>
                                        </div>

                                        <div class="card-toolbar">
                                            <a href="#" class="btn btn-sm btn-light-info mr-3"
                                                @click.prevent="refresh()"><i class="fas fa-redo-alt"></i> Aggiorna</a>
                                            <a v-if="isFiltered" href="#" class="btn btn-sm btn-danger mr-3"
                                                @click.prevent="removeFilters()">Rimuovi filtri</a>
                                            <a v-if="openFilters"
                                                @click.prevent="openFilters = false; $event.target.blur();" href="#"
                                                :class="{ 'btn-light-primary': !isFiltered, 'btn-light-info': isFiltered }"
                                                class="btn btn-icon btn-sm mr-1"><i
                                                    class="ki ki-arrow-up icon-nm"></i></a>
                                            <a v-if="!openFilters"
                                                @click.prevent="openFilters = true; $event.target.blur();" href="#"
                                                :class="{ 'btn-light-primary': !isFiltered, 'btn-light-info': isFiltered }"
                                                class="btn btn-icon btn-sm mr-1"><i
                                                    class="ki ki-arrow-down icon-nm"></i></a>
                                        </div>
                                    </div>

                                    <transition name="slide">
                                        <form v-if="openFilters" class="form" id="filters_form">
                                            <div class="card-body">
                                                <div v-if="field.filterable && field.is_visible_for_filter"
                                                    v-for="(field, index) in fields" class="form-group">
                                                    <label :for="'filter_' + field.name">{{ field.label }}</label>

                                                    <br v-if="show_filter_as(field) === 'datetime'">
                                                    <date-picker v-if="show_filter_as(field) === 'datetime'"
                                                        v-model="field.filter" type="datetime"
                                                        placeholder="Seleziona l'intervallo di ricerca"
                                                        format="DD/MM/YYYY HH:mm:ss" time-title-format="DD/MM/YYYY"
                                                        value-type="YYYY-MM-DD HH:mm:ss" range
                                                        :shortcuts="date_shortcuts" range-separator=" - "></date-picker>

                                                    <br v-if="show_filter_as(field) === 'date'">
                                                    <date-picker v-if="show_filter_as(field) === 'date'"
                                                        v-model="field.filter" type="date"
                                                        placeholder="Seleziona l'intervallo di ricerca"
                                                        format="DD/MM/YYYY" time-title-format="DD/MM/YYYY"
                                                        value-type="YYYY-MM-DD" range :shortcuts="date_shortcuts"
                                                        range-separator=" - "></date-picker>

                                                    <input :id="'filter_' + field.name"
                                                        v-if="show_filter_as(field) === 'integer' && !field.relation"
                                                        type="number"
                                                        class="form-control form-control-solid border-primary"
                                                        :placeholder="field.placeholder" v-model="field.filter" />

                                                    <multiselect style="width: 100%;" :id="'filter_' + field.name"
                                                        v-if="(show_filter_as(field) === 'integer' || show_filter_as(field) === 'has_many') && field.relation && (field.relation_type === 'belongsTo' || field.relation_type === 'belongsToMany')"
                                                        v-model="field.filter" :options="field.filter_options"
                                                        :multiple="true" :close-on-select="false"
                                                        :clear-on-select="false"
                                                        placeholder="Seleziona una o più opzioni"
                                                        :selectLabel="'Premi Invio per selezionare'"
                                                        :selectedLabel="'Selezionato'" :label="field.relation_fields"
                                                        :deselectLabel="'Premi Invio per deselezionare'" track-by="id">
                                                    </multiselect>

                                                    <input :id="'filter_' + field.name"
                                                        v-if="(show_filter_as(field) === 'string' || show_filter_as(field) === 'text') && field.format === 'email'"
                                                        type="email"
                                                        class="form-control form-control-solid border-primary"
                                                        :placeholder="field.placeholder" v-model="field.filter" />

                                                    <input :id="'filter_' + field.name"
                                                        v-if="((show_filter_as(field) === 'string' || show_filter_as(field) === 'text') && field.format === 'text') || (show_filter_as(field) === 'taxcode' && field.format === 'text')"
                                                        type="text"
                                                        class="form-control form-control-solid border-primary"
                                                        :placeholder="field.placeholder" v-model="field.filter" />

                                                    <select :id="'filter_' + field.name"
                                                        v-if="show_filter_as(field) === 'choice'"
                                                        class="form-control form-control-solid border-primary"
                                                        v-model="field.filter">
                                                        <option value="">Seleziona una opzione</option>
                                                    </select>

                                                    <b-form-group>
                                                        <b-form-radio-group v-if="show_filter_as(field) === 'boolean'"
                                                            :id="'filter_' + field.name" v-model="field.filter"
                                                            :options="[{ text: 'Qualsiasi', value: '' }, { text: 'Si', value: '1' }, { text: 'No', value: '0' }]"></b-form-radio-group>
                                                    </b-form-group>
                                                </div>
                                            </div>

                                            <div class="card-footer text-right">
                                                <button @click.prevent="doFilters()" type="submit"
                                                    class="btn btn-success mr-2">Applica</button>
                                                <button @click.prevent="removeFilters()" type="reset"
                                                    class="btn btn-danger">Rimuovi filtri</button>
                                            </div>
                                        </form>
                                    </transition>
                                </div>

                                <slot name="before-simple-search"></slot>

                                <div class="form-group mt-3" v-if="hasSearch">
                                    <div class="input-group">
                                        <b-form-input v-model="search" class="form-control form-control-solid"
                                            type="text" debounce="500"
                                            placeholder="Cerca nell'archivio..."></b-form-input>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" @click="clear"
                                                type="button">Reimposta</button>
                                        </div>
                                    </div>
                                </div>
                            </slot>

                            <slot name="after-filters"></slot>

                            <div class="table-responsive">
                                <table
                                    class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered">
                                    <slot name="tr-fields" v-bind:values="{ doSort: doSort, fields: fields }">
                                        <thead>
                                            <tr class="text-left">
                                                <th class="bg-primary" v-if="has_details">&nbsp;</th>

                                                <th class="bg-primary" v-if="hasCheckboxes">
                                                    <label class="checkbox checkbox-primary">
                                                        <input type="checkbox" v-model="allChecked">
                                                        <span></span>
                                                    </label>
                                                </th>

                                                <template v-for="(field, index) in fields"
                                                    v-if="field.is_visible_for_index">
                                                    <slot :name="'th-' + field.name"
                                                        v-bind:values="{ doSort: doSort, field: field, fields: fields }">
                                                        <th v-if="field.is_visible_for_index" class="bg-primary">
                                                            <span v-if="!field.sortable"
                                                                class="text-muted text-white">{{ field.label }}</span>
                                                            <a @click.prevent="doSort(field.name); $event.target.blur();"
                                                                href="
                                                                               javascript:void(0);"
                                                                v-if="field.sortable"
                                                                class="text-white text-hover-white">{{ field.label }}

                                                                <i v-if="field.ordering && field.ordering_direction === 'ASC'"
                                                                    class="flaticon2-arrow-up text-white"
                                                                    style="font-size: 0.7rem;"></i>
                                                                <i v-if="field.ordering && field.ordering_direction === 'DESC'"
                                                                    class="flaticon2-arrow-down text-white"
                                                                    style="font-size: 0.7rem;"></i>
                                                            </a>
                                                        </th>
                                                    </slot>
                                                </template>

                                                <slot name="th-actions">
                                                    <th class="bg-primary text-center pr-0"><span
                                                            class="text-white">Azioni</span></th>
                                                </slot>
                                            </tr>
                                        </thead>
                                    </slot>
                                    <tbody>
                                        <template v-if="items.length > 0" v-for="(item, index) in items">
                                            <tr :key="index" :class="{ 'table-primary': item.checked }">
                                                <td v-if="has_details">
                                                    <button v-if="typeof item.show === 'undefined' || !item.show"><i
                                                            class="fas fa-caret-square-right text-primary font-size-h1"
                                                            @click.prevent="$set(item, 'show', true);"></i></button>

                                                    <button v-if="typeof item.show !== 'undefined' && item.show"><i
                                                            class="fas fa-caret-square-down text-primary font-size-h1"
                                                            @click.prevent="$set(item, 'show', false)"></i></button>
                                                </td>

                                                <td v-if="hasCheckboxes">
                                                    <label
                                                        class="checkbox checkbox-outline checkbox-outline-2x checkbox-primary">
                                                        <input type="checkbox" v-model="item.checked">
                                                        <span></span>
                                                    </label>
                                                </td>

                                                <template v-for="(field, j) in fields"
                                                    v-if="field.is_visible_for_index">
                                                    <slot :name="'td-' + field.name"
                                                        v-bind:values="{ field: field, field_index: j, fields: fields, item: item, item_index: index }">
                                                        <td>
                                                            <div :class="{ 'justify-content-end': (field.format === 'currency' || field.format === 'integer') }"
                                                                class="d-flex align-items-center">
                                                                <div>
                                                                    <a v-if="show_filter_as(field) === 'file'"
                                                                        :href="appendToken(item[field.name]['href'])"
                                                                        target="_blank"
                                                                        :class="{ 'text-nowrap': field.format === 'currency' }"
                                                                        class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                                                            item[field.name]['name'] }}</a>

                                                                    <span v-else
                                                                        :class="{ 'text-nowrap': field.format === 'currency' }"
                                                                        class="text-dark-75 text-hover-primary mb-1 font-size-lg">{{
                                                                            item[field.name] }}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </slot>
                                                </template>

                                                <slot name="item-actions"
                                                    v-bind:values="{ fields: fields, item: item, item_index: index, doItemAction: doItemAction, appendToken: appendToken }">
                                                    <td v-if="(typeof item.actions !== 'undefined') && item.actions.length"
                                                        class="text-nowrap pr-0 text-center">
                                                        <template v-for="(action, j) in item.actions">
                                                            <a v-if="typeof action.is_external === 'undefined' || !action.is_external"
                                                                href="javascript:void(0);"
                                                                :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (item.actions.length - 1) ? ' mr-3' : ''))"
                                                                :title="action.label" :alt="action.label"
                                                                @click.prevent="doItemAction(action, item['id'])">
                                                                <i :class="action.icon"></i>
                                                            </a>

                                                            <a v-else :href="appendToken(action.url)" target="_blank"
                                                                :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (item.actions.length - 1) ? ' mr-3' : ''))"
                                                                :title="action.label" :alt="action.label">
                                                                <i :class="action.icon"></i>
                                                            </a>
                                                        </template>
                                                    </td>
                                                </slot>
                                            </tr>

                                            <tr v-if="has_details"
                                                :class="{ 'd-none': typeof item.show === 'undefined' || !item.show }">
                                                <td
                                                    :colspan="fields.length + 1 + (hasCheckboxes ? 1 : 0) + (has_details ? 1 : 0)">
                                                    <slot name="item-details"
                                                        v-bind:values="{ fields: fields, item: item, item_index: index, doItemAction: doItemAction }">
                                                        Item Details
                                                    </slot>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-if="items.length === 0">
                                            <tr>
                                                <td v-if="hasSearch || isFiltered"
                                                    :colspan="fields.length + 1 + (hasCheckboxes ? 1 : 0) + (has_details ? 1 : 0)"
                                                    class="font-weight-bolder text-center">{{ labels.none_search_found
                                                    }}
                                                </td>
                                                <td v-if="!hasSearch && !isFiltered"
                                                    :colspan="fields.length + 1 + (hasCheckboxes ? 1 : 0) + (has_details ? 1 : 0)"
                                                    class="font-weight-bolder text-center">{{ labels.none_archive_found
                                                    }}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>

                                    <slot name="footer">
                                        <tfoot>
                                            <tr>
                                                <th :colspan="fields.length + 1 + (hasCheckboxes ? 1 : 0) + (has_details ? 1 : 0)"
                                                    class="bg-primary" style="height: 1px;"></th>
                                            </tr>
                                        </tfoot>
                                    </slot>
                                </table>
                            </div>

                            <slot name="before-nav"></slot>

                            <slot name="pagination">
                                <div v-if="total" class="d-flex justify-content-between align-items-center flex-wrap">
                                    <div class="d-flex flex-wrap py-2 mr-3">
                                        <a v-if="current_page > 1" href="javascript:void(0);"
                                            @click.prevent="changePage(1); $event.target.blur();"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1"><i
                                                class="ki ki-bold-double-arrow-back icon-xs"></i></a>
                                        <a v-if="current_page === 1" href="javascript:void(0);"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1 disabled"
                                            disabled><i class="ki ki-bold-double-arrow-back icon-xs"></i></a>
                                        <a v-if="current_page > 1" href="javascript:void(0);"
                                            @click.prevent="changePage(current_page - 1); $event.target.blur();"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1"><i
                                                class="ki ki-bold-arrow-back icon-xs"></i></a>
                                        <a v-if="current_page === 1" href="javascript:void(0);"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1 disabled"
                                            disabled><i class="ki ki-bold-arrow-back icon-xs"></i></a>

                                        <a v-for="page in active_pages" href="javascript:void(0);"
                                            @click.prevent="changePage(page); $event.target.blur();"
                                            :class="{ active: page === current_page }"
                                            class="btn btn-icon btn-circle btn-sm border-0 btn-hover-primary mr-2 my-1">{{
                                                page }}</a>

                                        <a v-if="current_page < last_page" href="javascript:void(0);"
                                            @click.prevent="changePage(current_page + 1); $event.target.blur();"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1"><i
                                                class="ki ki-bold-arrow-next icon-xs"></i></a>
                                        <a v-if="current_page === last_page" href="javascript:void(0);"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1 disabled"
                                            disabled><i class="ki ki-bold-arrow-next icon-xs"></i></a>
                                        <a v-if="current_page < last_page" href="javascript:void(0);"
                                            @click.prevent="changePage(last_page); $event.target.blur();"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1"><i
                                                class="ki ki-bold-double-arrow-next icon-xs"></i></a>
                                        <a v-if="current_page === last_page" href="javascript:void(0);"
                                            class="btn btn-icon btn-circle btn-sm btn-light-primary mr-2 my-1 disabled"
                                            disabled><i class="ki ki-bold-double-arrow-next icon-xs"></i></a>
                                    </div>

                                    <div class="d-flex align-items-center py-3">
                                        <div class="mr-2 font-weight-bold">Pagina</div>

                                        <multiselect v-model="current_page" :options="select_pages" placeholder=""
                                            selectLabel="" selectedLabel="" deselectLabel="" showLabels=""
                                            :allow-empty="false" @input="changePage(current_page)"></multiselect>

                                        <div class="ml-2 mr-2 font-weight-bold">di {{ last_page }}</div>
                                    </div>
                                </div>

                                <div v-if="total" class="d-flex justify-content-between align-items-center flex-wrap">
                                    <div class="d-flex align-items-center flex-wrap py-2 mr-3">
                                        <div class="mr-2 font-weight-bold">Visualizza</div>

                                        <multiselect v-model="per_page" :options="num_per_page" placeholder=""
                                            selectLabel="" selectedLabel="" deselectLabel="" showLabels=""
                                            @input="changePage(1)" track-by="value" label="label" :allow-empty="false">
                                        </multiselect>

                                        <div class="ml-2 mr-3 font-weight-bold">{{ labels.plural }} per pagina</div>

                                        <div v-if="loading" class="d-flex align-items-center">
                                            <div class="mr-2 text-muted">In Caricamento...</div>
                                            <div class="spinner spinner-primary"></div>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center py-3">
                                        <span class="font-weight-bold">Visualizzazione di {{ to - from + (total ? 1 :
                                            0) }} {{ labels.plural }}
                                            {{ from }}-{{ to }} su un totale di {{ total }}</span>
                                    </div>
                                </div>
                            </slot>

                            <slot name="after-nav"></slot>
                        </div>
                    </slot>
                </div>
                <b-modal v-if="is_dialog" ref="modal" size="xl" hide-header hide-footer>
                    <component ref="modalcomponent" v-bind:is="modal.component" :base_url="modal.params.base_url"
                        :base_path="modal.params.base_path" :form_type="modal.params.form_type"
                        :form_variant="modal.params.form_variant"
                        :item_id="(typeof modal.params.id !== 'undefined' ? modal.params.id : 0)"
                        :fixed_filters="fixed_filters" :is_dialog="is_dialog" v-on:close-dialog="closeModal"
                        v-on:options-loaded="optionsLoaded" v-on:values-loaded="valuesLoaded">
                    </component>
                </b-modal>
            </slot>
        </div>
    </b-overlay>
</template>
<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import SmartForm from "@/view/components/SmartForm.vue";
import KTUtil from "@/assets/js/components/util";

export default {
    name: 'SmartTable',

    components: {
        SmartForm,
    },

    props: ['url', 'base_url', 'base_path', 'custom_class', 'fixed_filters', 'is_dialog', 'has_details', 'export_url', 'has_checkboxes'],

    data() {
        return {
            topTableClassId: '',
            modal: {
                component: '',
                params: {},
            },
            componentFields: [],
            componentOptionloaded: null,
            componentValuesloaded: null,

            first_loading: true,

            loading: false,
            fields: [],
            items: [],
            top_actions: [],
            actions: [],
            num_per_page: [],
            batch_actions: [],
            current_page: 1,
            last_page: 0,
            per_page: { value: 15, label: "15" },
            total: 0,
            from: 0,
            to: 0,
            num_pages: 10,

            search: '',

            hasCheckboxes: true,
            allChecked: false,

            sortBy: '',
            sortDir: 'ASC',

            openFilters: false,
            isFiltered: false,

            labels: {
                singular: '',
                plural: '',
                none_archive_found: '',
                none_search_found: '',
                description: '',
            },

            date_shortcuts: [
                {
                    text: 'Oggi',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        return [item, item];
                    },
                },
                {
                    text: 'Ieri',
                    onClick() {
                        const date = new Date();

                        const item = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item.setTime(item.getTime() - 3600 * 1000 * 24);

                        return [item, item];
                    },
                },
                {
                    text: 'Ultima settimana',
                    onClick() {
                        const date = new Date();

                        const item_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        const item_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item_from.setTime(item_to.getTime() - 3600 * 1000 * 24 * 7);

                        return [item_from, item_to];
                    },
                },
                {
                    text: 'Ultimo mese',
                    onClick() {
                        const date = new Date();

                        const item_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        const item_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item_from.setTime(item_to.getTime() - 3600 * 1000 * 24 * 30);

                        return [item_from, item_to];
                    },
                },
                {
                    text: 'Ultimi 3 mesi',
                    onClick() {
                        const date = new Date();

                        const item_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        const item_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item_from.setTime(item_to.getTime() - 3600 * 1000 * 24 * 90);

                        return [item_from, item_to];
                    },
                },
                {
                    text: 'Ultimi 6 mesi',
                    onClick() {
                        const date = new Date();

                        const item_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        const item_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item_from.setTime(item_to.getTime() - 3600 * 1000 * 24 * 180);

                        return [item_from, item_to];
                    },
                },
                {
                    text: 'Ultimo anno',
                    onClick() {
                        const date = new Date();

                        const item_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
                        const item_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

                        item_from.setTime(item_to.getTime() - 3600 * 1000 * 24 * 365);

                        return [item_from, item_to];
                    },
                },
            ],
        };
    },
    async created() {
        if (typeof this.has_checkboxes !== "undefined") this.hasCheckboxes = this.has_checkboxes;

        await this.getOptions();
        this.getItems();
        this.topTableClassId = this.generateRandomId();
    },

    computed: {
        select_pages() {
            let select_pages = [];

            for (let i = 1; i <= this.last_page; i++) {
                select_pages.push(i);
            }

            return select_pages;
        },

        hasFilters() {
            let hasFilters = false;

            for (var index in this.fields) {
                if (this.fields[index].filterable) hasFilters = true;
            }

            return hasFilters;
        },

        hasSearch() {
            let hasSearch = false;

            // for (var index in this.fields)
            // {
            //     if (((this.fields[index].type === 'string') || (this.fields[index].type === 'text')) && (this.fields[index].filterable)) hasSearch = true;
            // }
            //

            for (var index in this.fields) {
                if (this.fields[index].filterable) hasSearch = true;
            }

            return hasSearch;
        },

        active_pages() {
            let active_pages = [];

            let tmp = this.current_page - Math.floor(this.num_pages / 2);
            let check = this.last_page - this.num_pages + 1;
            let limit = ((check > 0) ? check : 1);
            let begin = ((tmp > 0) ? ((tmp > limit) ? limit : tmp) : 1);

            let i = begin;
            while ((i < (begin + this.num_pages)) && (i <= this.last_page)) {
                active_pages.push(i++);
            }

            return active_pages;
        },
    },

    watch: {
        search(search) {
            this.current_page = 1;

            this.getItems();
            this.scrollTop();
        },

        allChecked(checked) {
            for (let i in this.items) {
                this.items[i].checked = checked;
            }
        },
    },

    methods: {
        show_filter_as(field) {
            if (typeof field.format_filter !== 'undefined' && field.format_filter) {
                return field.format_filter;
            }

            return field.type;
        },

        doAction(action) {
            if (!action.is_external && this.is_dialog && (action.name !== 'export')) {
                this.componentOptionloaded = false;
                this.componentValuesloaded = false;

                this.$set(this.modal, 'component', 'SmartForm');
                this.$set(this.modal.params, 'base_url', this.base_url);
                this.$set(this.modal.params, 'base_path', this.base_path);
                this.$set(this.modal.params, 'form_type', action.name);
                this.$set(this.modal.params, 'id', 0);

                let vm = this;
                setTimeout(function () {
                    vm.$refs['modal'].show();
                }, 200);

                this.$set(this.modal, 'opened', true);
            }
            else if (action.name === 'export') {
                if (this.export_url !== "undefined" && this.export_url) {
                    this.$router.push(this.export_url);
                }
                else {
                    let url = this.base_path + "/export?page=" + this.current_page;
                    if (this.search) url += "&q=" + this.search;
                    if (this.sortBy) url += "&sortBy=" + this.sortBy;
                    if (this.sortDir) url += "&sortDir=" + this.sortDir;
                    if (this.hasFilters) {
                        let filtersQuerystring = this.getFiltersQueryString();
                        if (filtersQuerystring) {
                            url += "&" + filtersQuerystring;
                            this.isFiltered = true;
                        }
                    }

                    let fixedFiltersQueryString = this.getFixedFilterQueryString();
                    if (fixedFiltersQueryString) url += "&" + fixedFiltersQueryString;
                    url += '&token=' + JwtService.getToken();

                    window.open(ApiService.getApiUrl() + url);
                }
            }
            else {
                if (action.is_external) {
                    let url = this.base_path + "/" + action.name + "/?page=" + this.current_page;
                    if (this.search) url += "&q=" + this.search;
                    if (this.sortBy) url += "&sortBy=" + this.sortBy;
                    if (this.sortDir) url += "&sortDir=" + this.sortDir;
                    if (this.hasFilters) {
                        let filtersQuerystring = this.getFiltersQueryString();
                        if (filtersQuerystring) {
                            url += "&" + filtersQuerystring;
                            this.isFiltered = true;
                        }
                    }

                    let fixedFiltersQueryString = this.getFixedFilterQueryString();
                    if (fixedFiltersQueryString) url += "&" + fixedFiltersQueryString;
                    url += '&token=' + JwtService.getToken();

                    window.open(ApiService.getApiUrl() + url);
                }
                else {
                    this.$router.push(this.base_path + '/' + action.name);
                }
            }
        },

        doItemAction(action, id) {
            if (this.is_dialog) {
                this.componentOptionloaded = false;
                this.componentValuesloaded = false;

                this.$set(this.modal, 'component', 'SmartForm');
                this.$set(this.modal.params, 'base_url', this.base_url);
                this.$set(this.modal.params, 'base_path', this.base_path);
                this.$set(this.modal.params, 'form_type', (typeof action.form_type !== 'undefined' ? action.form_type : action.name));
                this.$set(this.modal.params, 'form_variant', (typeof action.form_variant !== 'undefined' ? action.form_variant : null));
                this.$set(this.modal.params, 'id', id);

                let vm = this;
                setTimeout(function () {
                    vm.$refs['modal'].show();
                }, 200);

                this.$set(this.modal, 'opened', true);
            }
            else {
                this.$router.push(this.base_path + '/' + action.url + '/' + id);
            }
        },

        refresh() {
            this.current_page = 1;

            this.getItems();
            this.scrollTop();
        },

        reload() {
            this.getItems();
        },

        closeModal() {
            let vm = this;
            setTimeout(function () {
                vm.$refs['modal'].hide();
            }, 200);

            this.$set(this.modal, 'opened', false);

            //if (refresh) this.refresh();

            this.componentOptionloaded = false;
            this.componentValuesloaded = false;

            this.$emit('close-modal');
        },

        removeFilters() {
            for (let i in this.fields) {
                this.$set(this.fields[i], 'filter', '');
            }

            this.current_page = 1;

            this.getItems();
            this.scrollTop();

            this.openFilters = false;
            this.isFiltered = false;
        },

        doFilters() {
            this.current_page = 1;

            this.getItems();
            this.scrollTop();

            this.openFilters = false;
        },

        getFiltersQueryString() {
            let filters = "";

            for (let i in this.fields) {
                if (typeof this.fields[i].filter !== "undefined" && this.fields[i].filter) {
                    if (filters) filters += "&";

                    if (this.fields[i].type === 'datetime' || this.fields[i].type === 'date') {
                        if (typeof this.fields[i].filter[0] !== "undefined") {
                            filters += encodeURIComponent(this.fields[i].name + '[start]') + '=' + encodeURIComponent(this.fields[i].filter[0]);
                        }
                        else {
                            filters += encodeURIComponent(this.fields[i].name + '[start]') + '=';
                        }

                        filters += "&";

                        if (typeof this.fields[i].filter[1] !== "undefined") {
                            filters += encodeURIComponent(this.fields[i].name + '[end]') + '=' + encodeURIComponent(this.fields[i].filter[1]);
                        }
                        else {
                            filters += encodeURIComponent(this.fields[i].name + '[end]') + '=';
                        }
                    }
                    else if ((this.fields[i].type === 'integer' || this.fields[i].type === 'has_many') && (this.fields[i].relation) && (this.fields[i].relation_type === 'belongsTo' || this.fields[i].relation_type === 'belongsToMany')) {
                        if (this.fields[i].filter.length) {
                            for (let j in this.fields[i].filter) {
                                filters += encodeURIComponent(this.fields[i].name + '[]') + '=' + encodeURIComponent(this.fields[i].filter[j].id);
                                if (j < (this.fields[i].filter.length - 1)) {
                                    filters += "&";
                                }
                            }
                        }
                    }
                    else if (this.fields[i].type === 'boolean') {
                        if (typeof this.fields[i].filter !== "undefined" && this.fields[i].filter) {
                            filters += encodeURIComponent(this.fields[i].name) + '=' + encodeURIComponent(this.fields[i].filter);
                        }
                    }
                    else {
                        filters += encodeURIComponent(this.fields[i].name) + '=' + encodeURIComponent(this.fields[i].filter);
                    }
                }
            }

            return filters;
        },

        getFixedFilterQueryString() {
            let filters = "";

            if (typeof this.fixed_filters !== "undefined") {
                for (let i in this.fixed_filters) {
                    if (filters) filters += "&";
                    filters += encodeURIComponent(this.fixed_filters[i].name) + '=' + encodeURIComponent(this.fixed_filters[i].filter);
                }
            }

            return filters;
        },

        doSort(field_name) {
            for (let i in this.fields) {
                this.$set(this.fields[i], 'ordering', false);

                if ((this.fields[i].name === field_name) && (this.fields[i].sortable)) {
                    this.$set(this.fields[i], 'ordering', true);
                    this.sortBy = field_name;

                    if (this.fields[i].ordering_direction === '') {
                        this.sortDir = 'ASC';
                    }
                    if (this.fields[i].ordering_direction === 'ASC') {
                        this.sortDir = 'DESC';
                    }
                    if (this.fields[i].ordering_direction === 'DESC') {
                        this.sortDir = 'ASC';
                    }

                    this.$set(this.fields[i], 'ordering_direction', this.sortDir);
                }
                else {
                    this.$set(this.fields[i], 'ordering_direction', '');
                }
            }

            this.getItems();
            this.scrollTop();
        },

        hasSlot(name = 'default') {
            return !!this.$slots[name] || !!this.$scopedSlots[name];
        },

        async getOptions() {
            this.loading = true;

            let url = this.url + "/options";

            let fixedFiltersQueryString = this.getFixedFilterQueryString();
            if (fixedFiltersQueryString) url += "?" + fixedFiltersQueryString;

            try {
                let response = await ApiService.query(url);

                this.top_actions = response.data.top_actions;
                this.actions = response.data.actions;
                this.num_per_page = response.data.num_per_page;
                this.batch_actions = response.data.batch_actions;
                this.fields = response.data.fields;
                if (typeof this.has_checkboxes === "undefined") this.hasCheckboxes = response.data.has_checkboxes;
                this.labels = response.data.labels;

                this.loading = false;
            }
            catch (error) {
                console.log(error);

                this.loading = false;
            }
        },

        getItems() {
            this.loading = true;

            this.isFiltered = false;

            let url = this.url + "?page=" + this.current_page;
            if (this.per_page) url += "&per_page=" + this.per_page.value;
            if (this.search) url += "&q=" + this.search;
            if (this.sortBy) url += "&sortBy=" + this.sortBy;
            if (this.sortDir) url += "&sortDir=" + this.sortDir;
            if (this.hasFilters) {
                let filtersQuerystring = this.getFiltersQueryString();
                if (filtersQuerystring) {
                    url += "&" + filtersQuerystring;
                    this.isFiltered = true;
                }
            }

            let fixedFiltersQueryString = this.getFixedFilterQueryString();
            if (fixedFiltersQueryString) url += "&" + fixedFiltersQueryString;

            ApiService.query(url)
                .then((response) => {
                    const items = response.data.data;
                    if (items.length) {
                        for (let i in items) {
                            if (typeof items[i].checked === 'undefined') {
                                items[i].checked = false;
                            }
                        }

                        for (let i in items) {
                            if (typeof items[i].show === 'undefined') {
                                items[i].show = false;
                            }
                        }
                    }

                    this.items = response.data.data;
                    this.current_page = response.data.current_page;
                    this.last_page = response.data.last_page;
                    this.total = response.data.total;
                    this.from = response.data.from;
                    this.to = response.data.to;

                    let per_page = response.data.per_page;
                    let found = this.num_per_page.find(function (item) {
                        return item.value === per_page;
                    });
                    this.per_page = found;

                    this.loading = false;

                    this.first_loading = false;
                })
                .catch((error) => {
                    this.isFiltered = false;

                    console.log(error);

                    this.loading = false;

                    this.first_loading = false;
                })
        },

        changePage(page) {
            this.current_page = page;

            this.getItems();
            this.scrollTop();
        },

        clear() {
            this.search = '';
        },

        setFields() {
            let componentFields = [];

            if (
                (typeof this.$refs.modalcomponent !== "undefined")
                &&
                (this.$refs.modalcomponent !== null)
                &&
                (typeof this.$refs.modalcomponent.fields !== 'undefined')
            ) {
                componentFields = this.$refs.modalcomponent.fields;
            }

            this.componentFields = componentFields;
        },

        optionsLoaded() {
            this.setFields();

            this.componentOptionloaded = true;

            this.$emit('options-loaded');
        },

        valuesLoaded() {
            this.setFields();

            this.componentValuesloaded = true;

            this.$emit('values-loaded');
        },

        scrollTop() {
            if (!this.first_loading) {
                setTimeout(() => {
                    let top = this.$el.querySelector('.toptable' + this.topTableClassId);
                    KTUtil.scrollTo(this.$el, 0, 500, -70);
                }, 100);
            }
        },

        appendToken(url) {
            if (url) {
                let parts = url.split('?');
                if (parts.length >= 2) {
                    return url += '&token=' + JwtService.getToken();
                }
                else {
                    return url += '?token=' + JwtService.getToken();
                }
            }

            return url;
        },

        generateRandomId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);

                return v.toString(16);
            });
        },
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__option--highlight {
    background-color: #0066CC;
}

.multiselect__option--selected {
    background-color: #187DE4;
    color: #FFFFFF;
}

.multiselect__option--selected.multiselect__option--highlight {
    background-color: #8950FC;
    color: #FFFFFF;
}

.multiselect {
    width: 100px;
    background-color: #E1F0FF;
    border: 1px solid #0066CC !important;
}

.multiselect__content-wrapper {
    background-color: #E1F0FF;
}

.multiselect__tags {
    background: transparent;
}

.multiselect__input,
.multiselect__single {
    background: transparent;
}

.multiselect__tag {
    background-color: #0066CC;
}

.checkbox.checkbox-outline.checkbox-primary>span {
    border-color: #0066CC;
}

.checkbox.checkbox-outline.checkbox-primary>input:focus~span {
    border-color: #0066CC;
}

input.mx-input {
    border-color: #0066CC;
}

input.mx-input.is-invalid {
    border-color: #F64E60 !important;
}

.mx-datepicker-main {
    font: inherit !important;
    color: inherit !important;
}

.mx-datepicker-sidebar {
    width: 160px;
}

.mx-datepicker-sidebar+.mx-datepicker-content {
    margin-left: 160px;
}

.btn-group-sm>.btn.btn-icon,
.btn.btn-icon.btn-sm {
    height: calc(1em + 1rem + 1px);
    width: calc(1em + 1rem + 1px);
}
</style>
